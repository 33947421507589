import React from 'react';

import { ReactComponent as Lemp } from './icons/lemp.svg';
import { ReactComponent as Lamp } from './icons/lamp.svg';
import { ReactComponent as cPanel } from './icons/cpanel.svg';


export const vpsBoxData = {
	heading: 'We will help you night and day.',
	subheading:
		'Your AWS server is already secure, fast, and best-in-class. Add a team of professional admins to build and monitor your new server.',
	boxes: [
		{
			icons: [cPanel],
			planLabel: 'VPS cPanel',
			planSlogan: 'FROM',
			amount: '$22.95',
			duration: 'mo',
			sale: 'Amazon EC2 Bare Metal',
			saleTm: 'Renews at same price',
			linkInternal: true,
			link: '/vps/?appType=0&app=0',
			linkLabel: 'Continue',
			features: [
				{ count: 'VPS cPanel/WHM', label: '— 14-Day Trial Then Bring Your Own License from cPanel' },
				{ count: 'Instant delivery', label: '— cPanel credentials emailed by server on launch' },
				{ count: 'Fully Managed', label: '- VPS EC2 Bare Metal' },
				{ count: 'Static Amazon IP', label: '— Global Leader' },
				{ count: 'Up to 4 Cores', label: '— Intel® Xeon® CPU' },
				{ count: 'Up to 16 GB', label: '— Guaranteed RAM' },
				{ count: 'Up to 320 GB', label: '— NVMe SSD Storage' },
				{ count: 'Up to 6TB', label: '— AWS Data Bandwidth' },
				{ count: 'SSL Included', label: '— Set up on custom domain' },
				{ count: 'Automated Backups', label: '— Optional' },
				{ count: 'Take snapshots', label: '— Optional' },
				{ count: 'Data Protection', label: '— security by Amazon' },
			],
			footer: (
				<>
					<strong>Premium Support</strong> — 24/7 × 365 days 
				</>
			),
		},
		{
			icons: [Lemp],
			planLabel: 'VPS WordPress',
			planSlogan: 'FROM',
			amount: '$22.95',
			duration: 'mo',
			sale: 'Amazon EC2 Bare Metal',
			saleTm: 'Renews at same price',
			linkInternal: true,
			link: '/vps/?appType=0&app=2',
			linkLabel: 'Continue',
			features: [
				{ count: 'VPS WordPress', label: '— VPS specialized for WordPress and configured with Yoast SEO' },
				{ count: 'Instant delivery', label: '— WP-Admin credentials emailed by server on launch' },
				{ count: 'Fully Managed', label: '- VPS EC2 Bare Metal' },
				{ count: 'Static Amazon IP', label: '— Global Leader' },
				{ count: 'up to 4 Cores', label: '— Intel® Xeon® CPU' },
				{ count: 'Up to 16 GB', label: '— Guaranteed RAM' },
				{ count: 'Up to 320 GB', label: '— NVMe SSD Storage' },
				{ count: 'Up to 6TB', label: '— AWS Data Bandwidth' },
				{ count: 'SSL Included', label: '— Set up on custom domain' },
				{ count: 'Automated Backups', label: '— Optional' },
				{ count: 'Take snapshots', label: '— Optional' },
				{ count: 'Data Protection', label: '— security by Amazon' },
			],
			footer: (
				<>
					<strong>Premium Support</strong> — 24/7 × 365 days 
				</>
			),
		},
		{
			icons: [Lamp],
			planLabel: 'VPS Plesk',
			planSlogan: 'FROM',
			amount: '$22.95',
			duration: 'mo',
			sale: 'Amazon EC2 Bare Metal',
			saleTm: 'Renews at same price',
			linkInternal: true,
			link: '/vps/?appType=0&app=1',
			linkLabel: 'Continue',
			features: [
				{ count: 'VPS Plesk', label: '— 14-Day Trial Then Bring Your Own License from Plesk' },
				{ count: 'Instant delivery', label: '— Plesk one-time login link emailed by server on launch' },
				{ count: 'Fully Managed', label: '- VPS EC2 Bare Metal' },
				{ count: 'Static Amazon IP', label: '— Global Leader' },
				{ count: 'Up to 4 Cores', label: '— Intel® Xeon® CPU' },
				{ count: 'Up to 16 GB', label: '— Guaranteed RAM' },
				{ count: 'Up to 320 GB', label: '— NVMe SSD Storage' },
				{ count: 'Up to 6TB', label: '— AWS Data Bandwidth' },
				{ count: 'SSL Included', label: '— Set up on custom domain' },
				{ count: 'Automated Backups', label: '— Optional' },
				{ count: 'Take snapshots', label: '— Optional' },
				{ count: 'Data Protection', label: '— security by Amazon' },
			],
			footer: (
				<>
					<strong>Premium Support</strong> — 24/7 × 365 days 
				</>
			),
		},
	],
};
